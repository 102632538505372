import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { NavController, Platform } from '@ionic/angular';
import { UiServices } from './ui-services';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  OAuthProvider,
  UserCredential,
  signInWithPopup,
} from 'firebase/auth';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { initializeApp } from 'firebase/app';
import {
  SignInWithApple,
  ASAuthorizationAppleIDRequest,
} from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { InitialKitComponent } from '../components/initial-kit/initial-kit.component';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public url: string = environment.url;
  public token = undefined;
  public userVerified = false;
  public emitLogin = new EventEmitter();
  public emitLogout = new EventEmitter();
  public isLogined = new EventEmitter();
  public auth = getAuth(initializeApp(environment.firebaseConfig));
  public dist = undefined;
  public client = undefined;
  public statusEmail = undefined;

  private _user = undefined;

  get user() {
    return this._user;
  }

  set user(value) {
    this._user = value;
  }

  constructor(
    private http: HttpClient,
    private storageSv: StorageService,
    private navCtrl: NavController,
    private uiSv: UiServices,
    private afAuth: AngularFireAuth,
    private googlePlus: GooglePlus,
    private platform: Platform,
    private signInWithApple: SignInWithApple,
    private trSv: TranslateService,
    private route: ActivatedRoute
  ) {}

  async getTokenUser(): Promise<string> {
    try {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const { token, user } = (await this.storageSv.get('userInfo')) || {};
          if (user?.rolId === 7 && !user.isVerify) {
            user.rolId = undefined;
          }
          this.token = token;
          this.user = user;
          this.isLogined.emit(user);
          this.userVerified = user?.isVerify;
          resolve(`${token?.type} ${token?.token}`);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  signUp(userInfo: any, type = 'register') {
    return new Promise(async (resolve, reject) => {
      await this.uiSv.showLoading();
      this.http.post(`${this.url}/auth/${type}`, userInfo).subscribe(
        async (res: any) => {
          this.goLogin(res.data);
          await this.uiSv.loading.dismiss();
          resolve(res);
        },
        async (err) => {
          await this.uiSv.loading.dismiss();
          reject(err);
        }
      );
    });
  }

  async comprobeKit() {
    if (
      this.user?.rolId === 5 &&
      !this.user.isVerify &&
      this.user.isVerifiedEmail
    ) {
      await this.uiSv.showModal(InitialKitComponent, null, 'initial-kit');
    } else if (this.user && !this.user.isVerifiedEmail) {
      this.navCtrl.navigateRoot('verify', { queryParamsHandling: 'merge' });
    }
  }

  async goLogin(userInfo) {
    localStorage.setItem('logined', '1');

    this.user = userInfo?.user;

    await this.storageSv.set('userInfo', userInfo);

    const goSreq = this.route.snapshot.queryParamMap.get('sReq');
    // VERIFICAR COMPORTAMIENTO AL VERIFICAR

    if (goSreq) {
      await this.navCtrl.navigateRoot('/special-request');
    } else if (this.user.isVerifiedEmail) {
      await this.navCtrl.navigateRoot('/home');
    }
    await this.comprobeKit();
    this.storageSv.saveCartSrc.next();
    this.emitLogin.emit();
  }

  recoveryPassword(body) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.url}/auth/recovery`, body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  postRecovery(body) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.url}/auth/reset`, body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async logout(type?) {
    this.user = undefined;
    this.statusEmail = undefined;
    await this.storageSv.remove('userInfo');
    await this.storageSv.remove('cart');
    localStorage.setItem('logined', '0');
    this.storageSv.saveCartSrc.next('logout');
    !type && this.navCtrl.navigateRoot('/home', { replaceUrl: true });
    this.emitLogout.emit();
    await this.uiSv.showToast('Saliste de tu cuenta satisfactoriamente');
  }

  async getUser(passedTime = true) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `${this.token?.type} ${this.token?.token}`,
      });

      this.http.get(`${this.url}/auth/index`, { headers }).subscribe(
        async (res: any) => {
          if (res.email) {
            res.email.passedTime = false;
            if (!res.email.timeSend) {
              res.email.passedTime = passedTime;
              res.email.timeSend = res.email.now;
            }
            this.statusEmail = res.email;
          }
          await this.saveUser(res.user);
          resolve(res);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  async saveUser(user) {
    this.user = user;
    const obj = {
      token: this.token,
      user: this.user,
    };
    await this.storageSv.set('userInfo', obj);
    return true;
  }

  async loginGoogle(domainId) {
    try {
      if (this.platform.is('capacitor')) {
        await this.loginGoogleAndroid(domainId);
      } else {
        const res = await this.afAuth.signInWithPopup(new GoogleAuthProvider());
        const user: any = res.user;
        const data: any = { ...user._delegate, domainId };
        await this.signUp(data, 'login');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async loginApple(domainId) {
    try {
      const provider = new OAuthProvider('apple.com');
      let user: UserCredential = undefined;
      if (this.platform.is('capacitor')) {
        user = await this.loginAppleNative(provider);
      } else {
        user = await this.loginAppleWeb(provider);
      }
      const data: any = { ...user.user, domainId };
      await this.signUp(data, 'login');
    } catch (err) {
      console.error(err);
    }
  }

  async loginAppleNative(provider: OAuthProvider) {
    try {
      const user = await this.signInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
        ],
      });
      const res = provider.credential({
        idToken: user.identityToken,
      });
      const userCredential = await signInWithCredential(this.auth, res);
      return userCredential;
    } catch (err) {
      console.error(err);
    }
  }

  async loginAppleWeb(provider: OAuthProvider) {
    try {
      provider.addScope('email');
      provider.addScope('name');
      const appleauth = await signInWithPopup(this.auth, provider);
      const credential = OAuthProvider.credentialFromResult(appleauth);
      const user = await signInWithCredential(this.auth, credential);
      return user;
    } catch (err) {
      console.error(err);
    }
  }

  async loginGoogleAndroid(domainId) {
    try {
      this.googlePlus
        .login({
          webClientId:
            '559396221040-la822f1juc0rgr56s0smtv5vpp27r70p.apps.googleusercontent.com',
          offline: true,
        })
        .then(async (res) => {
          const { idToken, accessToken } = res;
          this.onLoginSuccess(idToken, accessToken, domainId);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  onLoginSuccess(accessToken, accessSecret, domainId) {
    const credential = accessSecret
      ? GoogleAuthProvider.credential(accessToken, accessSecret)
      : GoogleAuthProvider.credential(accessToken);
    signInWithCredential(this.auth, credential).then(async (success) => {
      const data: any = { ...success.user, domainId };
      await this.signUp(data, 'login');
    });
  }

  async deleteAccount() {
    const headers = new HttpHeaders({
      Authorization: `${this.token?.type} ${this.token.token}`,
    });
    const msg = this.trSv.instant(
      'ESTAS_SEGURO_QUE_DESEAS_ELIMINAR_TU_USUARIO'
    );
    const { role } = await this.uiSv.presentAlert(msg, true, 'Aceptar');
    if (role === 'accept') {
      this.http
        .get(`${this.url}/auth/destroy`, { headers })
        .subscribe(async (res: any) => {
          if (res.status) {
            await this.logout();
          } else {
            await this.uiSv.showToast(res.data);
          }
        });
    }
  }
}
