export const environment = { 
      production: true,
      url: 'https://ctlg.neuronweb.online',
      uriBanner: 'https://ctlg.neuronweb.online/file/banner/',
      uriProduct: 'https://ctlg.neuronweb.online/file/product/',
      uriCatalogue: 'https://ctlg.neuronweb.online/file/catalogue/',
      uriImg: 'https://ctlg.neuronweb.online/file/parameter/',
    
      // url: 'https://api.olgarodriguez.com.co',
      // uriBanner: 'https://api.olgarodriguez.com.co/file/banner/',
      // uriProduct: 'https://api.olgarodriguez.com.co/file/product/',
      // uriCatalogue: 'https://api.olgarodriguez.com.co/file/catalogue/',
      // uriImg: 'https://api.olgarodriguez.com.co/file/parameter/',
    
      // url: 'http://italo:3001',
      // uriBanner: 'http://italo:3001/file/banner/',
      // uriProduct: 'http://italo:3001/file/product/',
      // uriCatalogue: 'http://italo:3001/file/catalogue/',
      // uriImg: 'http://italo:3001/file/parameter/',
      
      page: 'https://orgoldvenezuela.com',
    
      domainId: 1,
      domainName: 'OR GOLD',
      PORT: 5001,
      
      // NOMBRE              COLOR    DOMINIO (RECORDAR CAMBIAR DOMIONIO)
      // Seduction Girl Shop #000000, 1 *
      // Angel y Demonio Sex Shop #000000, 2 *
      // NeurON #ffffff, 4 --
      // Waay #ffffff, 5 * 
      // Neo Phone Store #00ade6, 6 https://neophonestore.com*
      // Happyland #ffffff, 7 --
      // DIMCA #ce1920, 8 --
      // OR #ffffff, 9 https://olgarodriguez.com.co*
      // KDesigns #C8AB81, 10
      // Yolita’s Gourmet #ff6702, 11 *
      // Tu cine 24_7 #1580F5, 12 *
      // Ludies Cake #ffc7d6, 14 *
      // Boutary Restaurant #000000, 15 restaurant.neuronweb.online*
      // Holmes Place #000000, 16 gym.neuronweb.online*
      // Mayo Clinic #000000, 17 clinica.neuronweb.online*
      // Hotel Nuevo Madrid #000000, 18 hotel.neuronweb.online *
      // Woolworths #000000, 19 supermercado.neuronweb.online *
    
      //Seduction
      firebaseConfig: {
        apiKey: "AIzaSyBUqBWZ6zhp3ge0MdD902QMWFp698VZ6dA",
        authDomain: "neuron-web-online.firebaseapp.com",
        projectId: "neuron-web-online",
        storageBucket: "neuron-web-online.appspot.com",
        messagingSenderId: "559396221040",
        appId: "1:559396221040:web:d3ec9cd55d6ce43bc9c4b5",
        measurementId: "G-C8FR37Q2JX"
      },
    
      stripePublishKey: 'pk_test_51LmLsOAzwGunGLiQRbZ2jLz0ryChB9EqF6G9qLL2ouTZPqplL6Yz8oPb7ffwwBFkfIZuCbwMszPCt2PVbqWJcdl100gamTmVYh',
      defaultImg: './assets/admin/defaultImage.png',
      whatsapp: 'https://api.whatsapp.com/send?phone=17867185949?&text=Hola podrias darme información.',
      ckConfig: 
      "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,embedbase," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,forms,horizontalrule,htmlwriter,iframe,indent," +
      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
      "newpage,pagebreak,pastefromword,pastetext,preview,print," +
      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
      "tabletools,templates,toolbar,undo,wysiwygarea",
    }
  