import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-info-sections',
  templateUrl: './info-sections.component.html',
  styleUrls: ['./info-sections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoSectionsComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;

  constructor() { }

  ngOnInit() {}

}
