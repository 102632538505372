import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Injectable,
  Input,
} from '@angular/core';
import { FileService } from 'src/app/services/file.service';
import { HttpService } from 'src/app/services/http.service';
import { LoginService } from 'src/app/services/login.service';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class ChangeAvatarComponent implements OnInit {
  @Input() set edit(value: any[]){
    if(value){
      value.map(img => {
        this.setImage(img);
      })
    }
    this.image = {};
  };
  @Input() isCatalogue: any;
  @Input() type: any;
  @Input() multiple: any;
  @Input() saveInUpload: any;
  @Output() saveImg = new EventEmitter();
  image: any = {};
  userInfo: any = {};
  loaded = true;

  constructor(
    private httpSv: HttpService,
    private fileSv: FileService,
    private uiSv: UiServices,
    private loginSv: LoginService
  ) {}

  async ngOnInit() {
    try{
      const res: any = await this.httpSv.itemIndex('auth');
      this.userInfo = res.user;
    }catch(err){
      console.error(err);
      
    }
  }

  setImage(img){
    setTimeout(() => {
      !this.image[this.type] && (this.image[this.type] = []);
      this.image[this.type] = img[this.type];
    }, 100);
  }

  async onFileSelected(fileImg) {
    try{
      const formData = new FormData();
      const files = fileImg.target.files;
      const images: any[] = await this.fileSv.uploadImages(files);
      this.fileSv.emptyArr();
      this.loaded = false;
      await this.uiSv.showLoading();
      for (const image of images) {
        formData.append(this.type, image.file);
        formData.append('type', this.type);
        const uploadFile: any = await this.httpSv.itemAction(formData, 'images', 'store');
        const savedImg = {};
        this.image[this.type] = uploadFile.data;
        savedImg[this.type] = [uploadFile.data];
        if(this.type === 'users'){
          this.loginSv.user.file = uploadFile.data;
        }
        this.saveImg.emit(savedImg);
        fileImg.target.value = null;
      }
      this.loaded = true;
      await this.uiSv.loading.dismiss();
    }catch(err){
      await this.uiSv.loading.dismiss();
      console.error(err);
    }
  }
}
