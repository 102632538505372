import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-card-buttons',
  templateUrl: './card-buttons.component.html',
  styleUrls: ['./card-buttons.component.scss'],
})
export class CardButtonsComponent implements OnInit {
  @Input() setDisabled = false;
  @Input() contador = 0;
  @Input() data: any = undefined;
  @Input() dist: any = undefined;
  @Input() client: any = undefined;
  @Input() existExistenceDist: any = undefined;
  @Input() existExistenceClient: any = undefined;
  @Output() counter = new EventEmitter();

  constructor(
    private translateSv: TranslateService,
    private uiSv: UiServices
  ) { }

  ngOnInit() {}

  async count(ev: Event){
    const evn = ev as CustomEvent;
    let contador = Number(evn.detail.value);
    if(contador < 0){
      this.contador = 0;
      return false;
    }
    const translate = this.translateSv.instant('SE_HA_ALCANZADO_EL_MAXIMO_DE_EXISTENCIA');
    if(this.dist){
      if(!this.data?.countHidden){
        if(
          this.existExistenceDist 
          && (contador > this.data?.existenceWSale) 
          && this.data?.existenceWSale !== null
        ){
          if(contador > this.data?.existenceWSale ) contador = this.data?.existenceWSale;
          return await this.uiSv.showToast(translate);
        }else if(
          !this.existExistenceDist 
          && (this.existExistenceClient && contador > this.data?.existence) 
          && this.data?.existence !== null
        ){
          if(contador > this.data.existence ) contador = this.data?.existence;
          return await this.uiSv.showToast(translate);
        }
      }
    }else if(
      !this.data?.countHidden 
      && this.client 
      && this.existExistenceClient 
      && (this.contador > this.data?.existence) 
      && this.data?.existence !== null
    ){
      if(contador > this.data?.existence ) this.contador = this.data?.existence;
      return await this.uiSv.showToast(translate);
    }
    this.counter.emit(contador);
  }
}
