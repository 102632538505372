import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FormServices {
  getDataSrc = new Subject();
  getDataObs = this.getDataSrc.asObservable();
  deleteListProductSrc = new Subject();
  deleteListProductObs = this.deleteListProductSrc.asObservable();
  copySrc = new Subject();
  copyObs = this.copySrc.asObservable();
  clearFormSrc = new Subject();
  clearFormObs = this.clearFormSrc.asObservable();
  haveError = new EventEmitter();

  constructor(
    private httpSv: HttpService
  ) { }

  async getForm(formName, domainId, params?){
    try{
      const res: any = await this.httpSv.itemIndex(
        'client/formInputs', `${formName}/${domainId}`,
        params
      );
      return res.data;
    }catch(err){
      console.error(err);
    }
  }
  
}
