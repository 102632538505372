import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() data: any = undefined;
  @Input() type: number = undefined;
  @Input() currency: any = undefined;
  @Input() currencyName: any = undefined;
  @Input() priceWSale: any;
  @Input() promotionalPriceWSale: any;
  @Output() emitOption = new EventEmitter();

  constructor(
    public paramSv: ParamServicesService,
    public loginSv: LoginService 
  ) { }

  ngOnInit() {
  }
  
  trackByItems(index, item){
    return item.id
  }

  setOption(opt){
    this.emitOption.emit(opt);
  }
}
