import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  roleId: number;

  constructor(
    private storageSv: StorageService,
    private popoverController: PopoverController,
    private navCtrl: NavController
  ) { }

  async ngOnInit() {
    const data = await this.storageSv.get('userInfo');
    this.roleId = data?.user?.rolId;
  }


  async goTo(route){
    this.navCtrl.navigateForward(route);
    await this.popoverController.dismiss();
  }

}
