import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http.service';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss'],
})
export class FavoriteListComponent implements OnInit {
  @Input() domainId = undefined;
  public users: any[] = [];
  public loaded = false;

  constructor(
    private httpSv: HttpService,
    public modalController: ModalController,
    private uiSv: UiServices,
    private trSv: TranslateService
  ) { }

  async ngOnInit() {
    const res: any = await this.httpSv.itemIndex('favorite');
    this.users = res.data;
    this.loaded = true;
  }

  setClient(user){
    user.user.favorite = true;
    this.modalController.dismiss(user.user);
  }

  trackByItems(index, item){
    return item.id
  }

  async deleteFavorite(user, i){
    try{
      const userInfo = user.user;
      const trEl = this.trSv.instant('DESEAS_ELIMINAR_A');
      const trA = this.trSv.instant('DE_TU_LISTA_DE_FAVORITOS');
      const { role } = await this.uiSv.presentAlert(`${trEl} ${userInfo.name} ${trA}`);
      if(role === 'accept'){
        await this.uiSv.showLoading();
        await this.httpSv.itemAction({id: userInfo.id}, 'favorite', 'destroy');
        await this.uiSv.loading.dismiss()
        this.users.splice(i, 1);
      }
    }catch(err){
      console.error(err);
      await this.uiSv.loading.dismiss()
    }
  }
}
