import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-tags',
  templateUrl: './popover-tags.component.html',
  styleUrls: ['./popover-tags.component.scss'],
})
export class PopoverTagsComponent implements OnInit {
  @Input() tags:any[] = [];
  constructor(private popoverCtrl:PopoverController) { }

  ngOnInit() {}

  closeModal(){
    this.popoverCtrl.dismiss();
  }

  deleteTag(index){
    this.tags.splice(index, 1);
  }
}
