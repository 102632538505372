import { Component, EventEmitter, Input, ChangeDetectionStrategy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-categorie',
  templateUrl: './card-categorie.component.html',
  styleUrls: ['./card-categorie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCategorieComponent implements OnInit {
  @Input() categorie: any = undefined;
  @Input() typeCategorie: any = undefined;
  @Input() customCategorie: any = undefined;
  @Output() emitCategorySrc = new EventEmitter(); 
  public customStyle = undefined;

  constructor() {
  }

  ngOnInit() {
    this.customStyle = this.customCategorie?.customStyle;
  }

  emitCategory(id){
    this.emitCategorySrc.emit(id);
  }

}
