import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-zoom-img',
  templateUrl: './zoom-img.component.html',
  styleUrls: ['./zoom-img.component.scss'],
})
export class ZoomImgComponent implements OnInit {
  @Input() data: any = undefined;
  @ViewChild('swiper') swiper: IonSlides;

  public img: string = '';
  public slidesOpts = {
    zoom: true
  }

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async zoomIn(zoom){
    const swiper = await this.swiper.getSwiper();
    zoom ? swiper.zoom.in() : swiper.zoom.out();
  }

  async close(){
    await this.modalController.dismiss();
  }


}
